import { appStrings } from "../../resources/strings/app";
import { Permissions } from "../../types";
import { ShowIfAuthorised } from "../authentication/ShowIfAuthorised";
import { Button } from "./Button";
import { Mode } from "./types/Modify";

interface Props {
  config: {
    entity: string;
    editLabel: string;
    saveLabel: string;
    resetLabel: string;
  };
  permissions: Permissions | null;
  mode: Mode;
  handleModeSwitch: () => void;
  isFooter?: boolean;
}
type Component = (props: Props) => JSX.Element;

export const SaveEntityButtons: Component = ({
  mode,
  handleModeSwitch,
  permissions,
  isFooter = false,
  config: { entity, editLabel, saveLabel, resetLabel },
}) => {
  if (mode === "view" && !isFooter) {
    return (
      <ShowIfAuthorised
        userPermissions={permissions}
        permission={appStrings.permissions.update}
        entity={entity}
      >
        <Button label={editLabel} type="button" onClick={handleModeSwitch} />
      </ShowIfAuthorised>
    );
  }

  if (mode !== "view") {
    return (
      <>
        {!isFooter && (
          <Button label={resetLabel} type="reset" variant="outlined" />
        )}
        <Button label={saveLabel} type="submit" />
      </>
    );
  }

  return <></>; // Don't render anything if it's a footer in view mode
};
