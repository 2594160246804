import { logger } from "../helpers/log-helpers";
import axios from "../libs/axios/axios-tags";
import type { ReadFunction, ReadResult } from "../types/API";
import type { TagItem } from "../types/documents/Tag";

const read: ReadFunction<TagItem> = async (id, _childId, signal?) => {
  try {
    const path = `/${id}`;
    const { data } = await axios.get<ReadResult<TagItem>>(path, { signal });
    return Promise.resolve(data);
  } catch (e) {
    const message = logger.error(e);
    return Promise.reject(message);
  }
};

/**
 * Modifies the tag group by adding a new tag.
 * @param tagGroup - The tag group to modify (e.g., "projects").
 * @param tagName - The new tag to be added.
 */
const modifyTagGroup = async (tagGroup: string, tagName: string) => {
  try {
    const payload = {
      eventType: "update",
      payload: {
        tagGroup,
        tags: [{ text: tagName }],
      },
    };

    const { data } = await axios.put("/submit", payload);
    return Promise.resolve(data);
  } catch (e) {
    const message = logger.error(e);
    return Promise.reject(message);
  }
};

export const api = {
  read,
  modifyTagGroup, // Add this method to your API
};
