import type { CellProps, Column } from "react-table";
import type { ClientData } from "../../types/documents";

import { formatDate, formatDateTime } from "../../helpers/date-helpers";
import { findValue } from "../../helpers/dropdown-helpers";
import { caseStrings } from "../../resources/strings/cases";
import { clientStrings as strings } from "../../resources/strings/clients";
import { DropdownOptions } from "../../types";

const byDate = <T extends { date: string }>(a: T, b: T) =>
  b.date.localeCompare(a.date);

export const tableColumns = (
  caseStatusTags: DropdownOptions,
  caseTypeTags: DropdownOptions
): Column<ClientData>[] => [
  {
    id: "name",
    Header: strings.labels.name,
    accessor: ({ forename, surname }) => `${forename} ${surname}`,
  },
  {
    id: "address",
    Header: strings.labels.address,
    accessor: ({ address1, address2, city, state, code }) =>
      [address1, address2, city, state, code].filter((s) => s).join(", "),
    minWidth: 300,
  },
  {
    id: "email",
    Header: strings.labels.email,
    accessor: "email",
  },
  {
    id: "dob",
    Header: strings.labels.dateOfBirth,
    accessor: "dateOfBirth",
    Cell: ({ value }) => <>{value ? formatDateTime(value) : "N/A"}</>,
  },
  {
    id: "caseStatus",
    Header: strings.labels.caseStatus,
    accessor: ({ cases }) => {
      const caseDetails = Object.values(cases ?? {})
        .filter((c) => c.active)
        .sort(byDate);
      return caseDetails.length
        ? findValue(caseDetails[0].status, caseStatusTags)
        : "N/A";
    },
  },
  {
    id: "caseType",
    Header: strings.labels.caseType,
    accessor: ({ cases }) => {
      const sorted = Object.values(cases ?? {})
        .filter((c) => c.active)
        .sort(byDate);
      return sorted.length
        ? sorted[0].type
            .map((type) =>
              type === strings.options.other
                ? sorted[0].typeOther
                : findValue(type, caseTypeTags)
            )
            .join(", ")
        : "N/A";
    },
  },
  {
    id: "closedCasesCount",
    Header: strings.labels.closedCases,
    accessor: ({ cases }) =>
      Object.values(cases ?? {})
        .filter((c) => c.active)
        .filter((c) =>
          caseStrings.options.closedCaseStatuses.includes(c.status)
        ).length,
  },
  {
    id: "conversationsCount",
    Header: strings.labels.conversationCount,
    accessor: ({ conversations }) =>
      Object.values(conversations ?? {}).filter((c) => c.active).length,
  },
  {
    id: "lastContact",
    Header: strings.labels.lastContact,
    accessor: ({ conversations }) => {
      const sorted = Object.values(conversations ?? {})
        .filter((c) => c.active)
        .sort(byDate);
      return sorted.length ? sorted[0].date : "N/A";
    },
    Cell: ({ value }: CellProps<ClientData, string>) => (
      <>{value && value !== "N/A" ? formatDate(value) : "N/A"}</>
    ),    
  },
];
