export const caseStrings = {
  header: {
    cases: "Cases",
    clients: "Clients",
    clientCases: "Client Cases",
    caseDetails: "Case Details",
    caseConversations: "Case Conversations",
    clientDetails: "Client Details",
    createCase: "Create Case",
    editCase: "Edit Case",
    deleteCase: "Delete Case",
  },
  labels: {
    caseId: "Case ID",
    clientId: "Client ID",
    clientName: "Client",
    sortKey: "Sort Key",
    tenantId: "Tenant ID",
    userId: "User ID",
    created: "Created",
    createdBy: "Created By",
    updated: "Updated",
    unknown: "Unknown",

    status: "Case Status",
    project: "Project",
    type: "Case Type",
    energyAdviceInterventions: "Energy Advice Interventions",
    referredFrom: "Referred From",
    contact: "Contact",
    additionalSupport: "Additional Support",
    onwardReferral: "Onward Referral",
    referralDate: "Referral Date",
    referralNotes: "Referral Notes",
    referralDetails: "Referral Details",
    caseProfile: "Case Profile",
    benefits: "Benefits",
    ages: "Ages",
    actionedBy: "Actioned By",
    notes: "Additional Case Notes",
    furtherContact: "Further Contact",
    fuelBankVouchers: "Fuel Bank Vouchers",
    fuelBankVoucherAmount: "Value of Fuel Bank Vouchers",
    fuelBankVoucherQuantity: "No. of Fuel Bank Vouchers",
    energyAppointments: "Energy Advice Appointments",
    savings: "Actual Savings",
    potentialSavings: "Potential Savings",

    projectOther: "Other Project - Please Specify",
    typeOther: "Other Case Type - Please Specify",
    contactOther: "Other Contact - Please Specify",
    referredFromOther: "Other Referrer - Please Specify",
    energyAdviceInterventionsOther: "Other Interventions - Please Specify",
    additionalSupportOther: "Other Support - Please Specify",

    save: "Save",
    reset: "Reset",
    edit: "Edit",
    newCase: "New Case",
    delete: "Delete",
    confirmDelete:
      "Are you sure you want to delete this case? This action cannot be undone.",

    closedCases: "No. of Closed Cases",

    add: "Add",
    addProject: "Add Project"
  },
  options: {
    yesNo: ["Yes", "No"],
    onwardReferral: "cb274279-480d-4d1c-b9c0-58f9b6859019",
    closedCaseStatuses: [
      "524654d0-7dd3-453e-83f6-8fe5d08823cd", // "Closed"
      "40d2dc0c-9768-4f64-81e6-2bbb908ef0c0", // "Complete"
    ],
    other: "Other",
  },
};
