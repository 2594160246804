import { useState } from "react";
import { Modal, Box, TextField, Grid, SxProps, Theme } from "@mui/material";
import { useTagGroup } from "../../hooks/tags/queries/useTagGroup";
import { api } from "../../services/tags.service";
import { Dropdown } from "../general/controls/Dropdown";
import type { CustomProps } from "../../components/general/types/Modify";
import type { CaseData } from "../../types/documents";
import { caseStrings as strings } from "../../resources/strings/cases";
import { Button } from "../general/Button";
import { BaseControl } from "../general/controls/BaseControl";

const dropdownStyles: SxProps<Theme> = {
  width: "100%",
};

interface Props extends CustomProps<CaseData> {}
type Component = (props: Props) => JSX.Element;

export const ProjectsDropdown: Component = (props) => {
  const { handleChange, data } = props;

  const selectedValue = Array.isArray(data.project)
    ? data.project
    : data.project
    ? [data.project]
    : [];

  const [options, loading, refetch] = useTagGroup([], "projects");

  const [showModal, setShowModal] = useState(false);
  const [newProject, setNewProject] = useState("");

  const handleAddProject = async () => {
    if (newProject.trim()) {
      await api.modifyTagGroup("projects", newProject);
      setShowModal(false);
      setNewProject("");
      refetch();
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={10}>
          <BaseControl control={true}>
            <Box sx={dropdownStyles}>
              <Dropdown<CaseData>
                config={{
                  name: "project",
                  label: "Project",
                  required: true,
                  select: true,
                  SelectProps: {
                    multiple: true,
                  },
                  value: selectedValue,
                  options: loading
                    ? [{ label: "Loading...", value: "loading", disabled: true }]
                    : options,
                }}
                handleChange={handleChange}
              />
            </Box>
          </BaseControl>
        </Grid>

        <Grid item xs={12} md={2}>
          <BaseControl control={true}>
            <Button
              fullWidth
              variant="contained"
              onClick={() => setShowModal(true)}
              label={strings.labels.addProject}
            />
          </BaseControl>
        </Grid>
      </Grid>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
            boxShadow: 24,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <TextField
            label="New Project"
            fullWidth
            value={newProject}
            onChange={(e) => setNewProject(e.target.value)}
          />
          <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleAddProject}
              label={strings.labels.add}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
