import { Loader } from "../general/Loader";
import { SaveEntityButtons } from "../general/SaveEntityButtons";
import type { CustomProps } from "../general/types/Modify";
import { appStrings } from "../../resources/strings/app";
import { caseStrings as strings } from "../../resources/strings/cases";

interface Props extends CustomProps<any> {}
type Component = (props: Props) => JSX.Element;

export const CaseFooterActions: Component = (props) => {
  return (
    <div style={{ marginTop: "20px", textAlign: "right" }}>
      <Loader active={false} inline>
        <SaveEntityButtons
          {...props}
          isFooter={true}
          config={{
            entity: appStrings.entities.cases,
            editLabel: strings.labels.edit,
            saveLabel: strings.labels.save,
            resetLabel: strings.labels.reset,
          }}
        />
      </Loader>
    </div>
  );
};
