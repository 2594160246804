import type {
  ComponentConfiguration,
  Content,
} from "../../components/general/types/Modify";
import type { CaseData } from "../../types/documents";

import { CaseBreadcrumbHeader } from "../../components/cases/CaseBreadcrumbHeader";
import { appStrings } from "../../resources/strings/app";
import { caseStrings as strings } from "../../resources/strings/cases";
import { ConversationList } from "../conversations/ConversationList";

import { CaseFooterActions } from "../../components/cases/CaseFooterAction";
import { ProjectsDropdown } from "../../components/cases/ProjectsDropdown";


const required: (keyof CaseData)[] = [
  "status",
  "type",
  "project",
  "referredFrom",
  "referralDate",
  "contact",
  "energyAdviceInterventions",
  "energyAppointments",
  "additionalSupport",
  "fuelBankVouchers",
  "savings",
];

export const constraints: Partial<Record<keyof CaseData, any>> =
  required.reduce(
    (req, field) => ({
      ...req,
      [field]: {
        presence: {
          allowEmpty: false,
          message: `^${strings.labels[field]} is required`,
        },
      },
    }),
    {}
  );
constraints.fuelBankVoucherQuantity = {
  numericality: {
    greaterThanOrEqualTo: 0,
    onlyInteger: true,
  },
};

export const componentConfiguration: ComponentConfiguration<CaseData> = ({
  data,
}) => [
  {
    key: "header",
    ariaLabel: "case header",
    content: [
      {
        controltype: "custom",
        Component: CaseBreadcrumbHeader,
        md: 12,
        control: false,
      },
    ],
  },
  {
    key: "details",
    ariaLabel: "case details",
    content: [
      {
        controltype: "header",
        text: strings.header.caseDetails,
      },
      {
        controltype: "dropdown",
        name: "status",
        label: strings.labels.status,
        required: true,
        tagConfig: { tagGroup: "caseStatus" },
      },
      {
        controltype: "dropdown",
        name: "type",
        label: strings.labels.type,
        required: true,
        SelectProps: { multiple: true },
        tagConfig: { tagGroup: "caseType" },
        other: {
          name: "typeOther",
          label: strings.labels.typeOther,
        },
      },
      {
        controltype: "custom",
        name: "project",
        label: strings.labels.project,
        required: true,
        Component: ProjectsDropdown,
      },            
      {
        controltype: "header",
        text: strings.labels.referralDetails,
      },
      {
        controltype: "dropdown",
        name: "referredFrom",
        label: strings.labels.referredFrom,
        required: true,
        tagConfig: { tagGroup: "referredFrom" },
        other: {
          name: "referredFromOther",
          label: strings.labels.referredFromOther,
        },
      },
      {
        controltype: "datepicker",
        name: "referralDate",
        required: true,
        label: strings.labels.referralDate,
      },
      {
        controltype: "input",
        name: "referralNotes",
        label: strings.labels.referralNotes,
        multiline: true,
        rows: 5,
        md: 12,
      },
      {
        controltype: "header",
        text: strings.labels.caseProfile,
      },
      {
        controltype: "dropdown",
        name: "contact",
        label: strings.labels.contact,
        required: true,
        tagConfig: { tagGroup: "contact" },
        other: {
          name: "contactOther",
          label: strings.labels.contactOther,
        },
      },
      {
        controltype: "dropdown",
        name: "energyAdviceInterventions",
        label: strings.labels.energyAdviceInterventions,
        required: true,
        tagConfig: { tagGroup: "energyAdviceInterventions" },
        other: {
          name: "energyAdviceInterventionsOther",
          label: strings.labels.energyAdviceInterventionsOther,
        },
      },
      {
        controltype: "radiobutton",
        name: "energyAppointments",
        required: true,
        label: strings.labels.energyAppointments,
        options: strings.options.yesNo,
        row: true,
      },
      {
        controltype: "dropdown",
        name: "additionalSupport",
        label: strings.labels.additionalSupport,
        required: true,
        SelectProps: { multiple: true },
        tagConfig: { tagGroup: "additionalSupport" },
        other: {
          name: "additionalSupportOther",
          label: strings.labels.additionalSupportOther,
        },
      },
      ...(data.additionalSupport.includes(strings.options.onwardReferral)
        ? [
            {
              controltype: "input",
              name: "onwardReferral",
              label: strings.labels.onwardReferral,
            } as Content<CaseData>,
          ]
        : []),
      {
        controltype: "radiobutton",
        name: "fuelBankVouchers",
        label: strings.labels.fuelBankVouchers,
        options: strings.options.yesNo,
        row: true,
        required: true,
      },
      ...(data.fuelBankVouchers === "Yes"
        ? ([
            {
              controltype: "input",
              name: "fuelBankVoucherQuantity",
              label: strings.labels.fuelBankVoucherQuantity,
            },
            {
              controltype: "input",
              name: "fuelBankVoucherAmount",
              label: strings.labels.fuelBankVoucherAmount,
            },
          ] as Content<CaseData>[])
        : []),
      {
        controltype: "input",
        name: "potentialSavings",
        label: strings.labels.potentialSavings,
        required: true,
      },
      {
        controltype: "input",
        name: "savings",
        label: strings.labels.savings,
        required: true,
      },
      {
        controltype: "header",
        text: strings.labels.notes,
      },
      {
        controltype: "input",
        name: "notes",
        label: strings.labels.notes,
        multiline: true,
        rows: 5,
        md: 12,
      },
    ],
  },
  {
    key: "footer",
    content: [
      {
        controltype: "custom",
        Component: CaseFooterActions,
        md: 12,
        control: false,
      },
    ],
  },
  {
    key: "conversationsList",
    modes: ["update", "view"],
    userAccess: {
      entity: appStrings.entities.conversations,
      permission: appStrings.permissions.read,
    },
    content: [
      {
        controltype: "header",
        text: strings.header.caseConversations,
      },
      {
        controltype: "custom",
        Component: ConversationList,
        control: false,
        md: 12,
      },
    ],
  },
];
