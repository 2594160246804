export const clientStrings = {
  header: {
    clients: "Clients",
    clientDetails: "Client Details",
    address: "Address",
    householdDetails: "Household Details",
    createClient: "Create Client",
    editClient: "Edit Client",
    deleteClient: "Delete Client",
    clientCases: "Client Cases",
  },
  labels: {
    save: "Save",
    reset: "Reset",
    edit: "Edit",
    search: "Search",
    clientId: "Client ID",
    status: "Status",
    created: "Created",
    updated: "Updated",
    address: "Address",
    createClient: "New Client",

    title: "Title",
    forename: "Forename",
    surname: "Surname",
    email: "Email Address",
    dateOfBirth: "Date of Birth",
    mobileNo: "Mobile No.",
    landlineNo: "Landline No.",
    phoneNo: "Phone No.",
    idVerified: "ID Verified",

    address1: "Address 1",
    address2: "Address 2",
    city: "Town / City",
    state: "County",
    code: "Postcode",
    country: "Country",

    benefits: "Benefits",
    householdIncome: "Household Income",
    householdDetails: "Household Details",
    disabilities: "Disabilities",
    disabilityDetails: "Disability Details",
    newsletterSubscription: "Newsletter Subscription",
    gdpr: "GDPR",
    clientMandate: "Client Mandate",
    thirdPartyMandate: "Third Party Mandate",
    propertyStatus: "Property Status",
    noOfBedrooms: "No. of Bedrooms",
    councilTaxBand: "Council Tax Band",
    epcRating: "EPC",
    householdWithChildrenUnder5: "Household with children under 5",
    fuelPovertyIndicators: "Fuel Poverty Indicators",
    meterType: "Meter Type",
    suppliers: "Suppliers",
    fuelType: "Fuel Type",
    noOfChildren: "Number of Children",
    noOfAdults: "Number of Adults",
    surveys: "Surveys",

    newCase: "New Case",
    cases: "Cases",
    conversations: "Conversations",
    name: "Name",

    titleOther: "Other Title - Please Specify",
    benefitsOther: "Other Benefits - Please Specify",
    propertyStatusOther: "Other Property Status - Please Specify",
    fuelPovertyIndicatorsOther: "Other Fuel Poverty Indicator - Please Specify",
    meterTypeOther: "Other Meter Type - Please Specify",
    suppliersOther: "Other Supplier - Please Specify",
    fuelTypeOther: "Other Fuel Type - Please Specify",
    delete: "Delete",
    confirmDelete:
      "Are you sure you want to delete this client? This action cannot be undone.",

    caseStatus: "Case Status",
    caseType: "Case Type",
    closedCases: "No. of Closed Cases",
    conversationCount: "No. of Conversations",
    lastContact: "Last Contact",

    reapUser: "REAP User"
  },
  options: {
    other: "Other",
    yesNo: ["Yes", "No"],
  },
};
