import { createDropdownOptions } from "../../../helpers/dropdown-helpers";
import { api } from "../../../services/tags.service";
import type { DropdownOptions } from "../../../types";
import type { TagItem } from "../../../types/documents";
import { useReadQuery } from "../../general/useReadQuery";

const { read } = api;

const defaultDropdownOptions: DropdownOptions = [];
const defaultTagGroup = "";

export const useTagGroup = (
  defaultOptions: DropdownOptions = defaultDropdownOptions,
  tagGroup = defaultTagGroup
) => {
  const [item, loading, error, refetch] = useReadQuery<TagItem>(
    { tagGroup: "", name: "", tags: [] },
    `tags/${tagGroup}`,
    read,
    tagGroup
  );

  if (error) {
    console.error(`Error fetching tags for ${tagGroup}:`, error);
  }

  let options = defaultOptions;
  if (item?.tags?.length) {
    options = createDropdownOptions(item.tags, "id", "text");
  }

  return [options, loading, refetch] as const;
};
