import { ConversationBreadcrumbHeader } from "../../components/conversations/ConversationBreadcrumbHeader";
import { ConversationFooterActions } from "../../components/conversations/ConversationFooterActions";
import type {
  ComponentConfiguration,
  Content,
} from "../../components/general/types/Modify";
import { conversationStrings as strings } from "../../resources/strings/conversations";
import type { ConversationData } from "../../types/documents/Conversation";

const required: (keyof ConversationData)[] = [
  "date",
  "appointmentInformation",
  "notes",
  "actionRequired",
  "actionCompleted",
];

export const constraints: Partial<Record<keyof ConversationData, any>> =
  required.reduce(
    (req, field) => ({
      ...req,
      [field]: {
        presence: {
          allowEmpty: false,
          message: `^${strings.labels[field]} is required`,
        },
      },
    }),
    {}
  );

export const componentConfiguration: ComponentConfiguration<
  ConversationData
> = ({ data }) => [
  {
    key: "header",
    ariaLabel: "case header",
    content: [
      {
        controltype: "custom",
        Component: ConversationBreadcrumbHeader,
        md: 12,
        control: false,
      },
    ],
  },
  {
    key: "details",
    ariaLabel: "conversation details",
    content: [
      {
        controltype: "header",
        text: strings.header.conversationDetails,
      },
      {
        controltype: "datetimepicker",
        name: "date",
        label: strings.labels.date,
        required: true,
      },
      {
        controltype: "dropdown",
        name: "appointmentInformation",
        label: strings.labels.appointmentInformation,
        required: true,
        tagConfig: { tagGroup: "appointmentInformation" },
        other: {
          name: "appointmentInformationOther",
          label: strings.labels.appointmentInformationOther,
        },
      },
      {
        controltype: "input",
        name: "notes",
        label: strings.labels.notes,
        required: true,
        multiline: true,
        rows: 5,
        md: 12,
      },
      {
        controltype: "dropdown",
        name: "actionRequired",
        label: strings.labels.actionRequired,
        required: true,
        tagConfig: { tagGroup: "actionRequired" },
        other: {
          name: "actionRequiredOther",
          label: strings.labels.actionRequiredOther,
        },
      },
      {
        controltype: "radiobutton",
        name: "actionCompleted",
        label: strings.labels.actionCompleted,
        options: strings.options.yesNo,
        row: true,
        required: true,
      },
      ...(data.actionCompleted === "Yes"
        ? [
            {
              controltype: "input",
              name: "furtherAction",
              label: strings.labels.furtherAction,
              multiline: true,
              rows: 3,
              md: 12,
            } as Content<ConversationData>,
          ]
        : []),
    ],
  },
  {
    key: "footer",
    content: [
      {
        controltype: "custom",
        Component: ConversationFooterActions,
        md: 12,
        control: false,
      },
    ],
  },
];
