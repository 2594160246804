import type { RouteProps } from "../../types";
import type { ReportData, ReportFilters, TagItem } from "../../types/documents";

import { Box, Stack } from "@mui/material";
import { useQueries } from "@tanstack/react-query";
import { useState } from "react";
import { BreadcrumbHeader } from "../../components/general/BreadcrumbHeader";
import { CSVExportButton } from "../../components/reports/ExportButton";
import { ReportFilterBar } from "../../components/reports/ReportDateFilter";
import { useReadQuery } from "../../hooks/general/useReadQuery";
import { reportFields, reportStrings } from "../../resources/strings/reports";
import { api } from "../../services/reports.service";
import { api as tagsApi } from "../../services/tags.service";
import { TableContainer } from "../general/TableContainer";
import { tableColumns } from "./tableColumns";
import { ClientsWithSurveysExport } from "../../components/reports/ClientsWithSurveysExport";
import { ClientsWithNewslettersExport } from "../../components/reports/ClientsWithNewslettersExport";

export const ReportsContainer = (props: RouteProps) => {
  const [filters, setFilters] = useState<ReportFilters>({});

  // These queries use the same keys as the calls from the table component,
  // so duplicate network requests aren't actually made
  const reportQueries = useQueries({
    queries: Object.keys(reportFields).map((propertyName) => ({
      queryKey: [`report-${propertyName}`, 0, 20, { propertyName, ...filters }],
      queryFn: () => api.query({ query: { propertyName, ...filters } }),
    })),
  });

  const tagQueries = useQueries({
    queries: Object.keys(reportFields).map((name) => ({
      queryKey: [`tags/${name}`, name, null],
      queryFn: () => tagsApi.read(name),
    })),
  });

  const data = Object.values(reportFields).map((displayName, index) => ({
    data: reportQueries[index].data?.items ?? [],
    tags: tagQueries[index].data?.item?.tags ?? [],
    header: displayName,
  }));
  return (
    <>
      <BreadcrumbHeader
        crumbs={[{ text: reportStrings.header.report }]}
        children={
          <>
            <CSVExportButton
              tableDefinition={tableColumns}
              rawData={data}
              filters={filters}
            />
            <ClientsWithSurveysExport />
            <ClientsWithNewslettersExport />
          </>
        }
      />

      <Box padding={2}>
        <ReportFilterBar
          handleFilterChange={(key, val) =>
            setFilters((old) => ({ ...old, [key]: val }))
          }
          clearFilters={() => setFilters({})}
          filters={filters}
        />
      </Box>

      <Stack spacing={5}>
        {Object.entries(reportFields).map(([field, displayName]) => (
          <ReportTable
            key={field}
            propertyName={field}
            header={displayName}
            dateRange={filters}
            {...props}
          />
        ))}
      </Stack>
    </>
  );
};

interface TableProps extends RouteProps {
  propertyName: string;
  header: string;
  dateRange: ReportFilters;
}

const ReportTable = ({
  propertyName,
  header,
  dateRange,
  ...routeProps
}: TableProps) => {
  // We want the tag order as well, so we can't use the
  // useTagGroup hook which only returns id/text
  const [{ tags }] = useReadQuery<TagItem>(
    { tagGroup: "", name: "", tags: [] },
    `tags/${propertyName}`,
    tagsApi.read,
    propertyName
  );

  return (
    <TableContainer<ReportData>
      {...routeProps}
      queryKey={`report-${propertyName}`}
      api={api}
      tableColumns={tableColumns(header, tags, dateRange)}
      defaultSort={[{ id: "order", desc: false }]}
      filters={{ propertyName, ...dateRange }}
      dense
      hidePagination
    />
  );
};
