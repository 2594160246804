import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { Button } from "../general/Button";
import { Loader } from "../general/Loader";
import { api } from "../../services/clients.service";
import { reportStrings } from "../../resources/strings/reports";

interface Props {
  fileName?: string;
}

export const ClientsWithSurveysExport = ({
  fileName = "clients_with_survey_subscription.csv",
}: Props) => {
  const [exportData, setExportData] = useState<any[][]>([]);
  const [isExporting, setIsExporting] = useState(false);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const csvId = "csv-download-surveys"; // Unique ID for Surveys export

  const onExport = async () => {
    console.log("Starting export...");
    setIsExporting(true);
    setExportData([]);
    setTriggerDownload(false);

    try {
      const response = await api.getClientsWithSurveys();
      console.log("API Response:", response);

      if (!response || !response.items || response.items.length === 0) {
        console.error("No client data found for export.");
        setIsExporting(false);
        return;
      }

      const output = [
        ["First Name", "Surname", "Email"],
        ...response.items.map((client) => [
          client.forename?.trim() || "N/A",
          client.surname?.trim() || "N/A",
          client.email?.trim() || "N/A",
        ]),
      ];

      console.log("Formatted CSV Data:", output);
      setExportData(output);
      setTriggerDownload(true);
    } catch (error) {
      console.error("Error fetching survey clients:", error);
    } finally {
      setIsExporting(false);
    }
  };

  useEffect(() => {
    if (triggerDownload && exportData.length > 0) {
      console.log("Triggering CSV download...");
      document.getElementById(csvId)?.click(); // Uses the unique ID for Surveys
      setTriggerDownload(false);
    }
  }, [exportData, triggerDownload]);

  return (
    <>
      <Button
        onClick={onExport}
        label={
          <Loader inline text="" active={isExporting}>
            <span>{reportStrings.labels.exportSurverySubscribers}</span>
          </Loader>
        }
      />

      <CSVLink
        id={csvId} // Unique ID
        data={exportData}
        filename={fileName}
        className="hidden"
      />
    </>
  );
};
