import type { Column } from "react-table";
import type { UserData } from "../../types/documents";
import type { ConversationData } from "../../types/documents/Conversation";

import { formatDateTime } from "../../helpers/date-helpers";
import { findValue } from "../../helpers/dropdown-helpers";
import { conversationStrings as strings } from "../../resources/strings/conversations";
import { DropdownOptions } from "../../types";

export const tableColumns = (
  appointmentInformationOptions: DropdownOptions,
  actionRequiredOptions: DropdownOptions,
  users: UserData[]
): Column<ConversationData>[] => [
  {
    id: "date",
    Header: strings.labels.date,
    accessor: "date",
    Cell: ({ value }) => <>{value ? formatDateTime(value) : "N/A"}</>,
  },
  {
    id: "appointmentInformation",
    Header: strings.labels.appointmentInformation,
    accessor: ({ appointmentInformation, appointmentInformationOther }) =>
      appointmentInformation === strings.options.other
        ? appointmentInformationOther
        : findValue(appointmentInformation, appointmentInformationOptions),
  },
  {
    id: "actionRequired",
    Header: strings.labels.actionRequired,
    accessor: ({ actionRequired, actionRequiredOther }) =>
      actionRequired === strings.options.other
        ? actionRequiredOther
        : findValue(actionRequired, actionRequiredOptions),
  },
  {
    id: "dateCompleted",
    Header: strings.labels.actionCompletedDate,
    accessor: "actionCompletedDate",
    Cell: ({ value }) => <>{value ? formatDateTime(value) : "N/A"}</>,
  },
  {
    id: "createdBy",
    Header: strings.labels.createdBy,
    accessor: ({ userId }) => {
      const user = users.find((user) => user.userId === userId);
      if (!user) return strings.labels.unknown;
      return `${user.forename} ${user.surname}`;
    },
  },
];
