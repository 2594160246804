import type { ClientFilters } from "../../types/documents/Client";

import { Grid } from "@mui/material";
import { useForm } from "../../hooks/general/useForm";
import { clientStrings } from "../../resources/strings/clients";
import { Button } from "../general/Button";
import { BaseControl } from "../general/controls/BaseControl";
import { Input } from "../general/controls/Input";
import { Dropdown } from "../general/controls/Dropdown";
import { useUsers } from "../../hooks/general/useUsers";

interface Props {
  filters: ClientFilters;
  handleFilterChange: (name: keyof ClientFilters, value: any) => void;
  clearFilters: () => void;
}
type Component = (props: Props) => JSX.Element;

export const ClientSearchBar: Component = ({
  filters,
  handleFilterChange,
  clearFilters,
}) => {
  const [currentFilters, handleChange] = useForm<ClientFilters>(filters);
  const [users] = useUsers();

  const userOptions = users.map((user) => ({
    label: `${user.forename} ${user.surname}`,
    value: user.userId,
  }));

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    handleFilterChange("nameSearch", currentFilters.nameSearch);
    handleFilterChange("addressSearch", currentFilters.addressSearch);
    handleFilterChange("emailSearch", currentFilters.emailSearch);
    handleFilterChange("phoneSearch", currentFilters.phoneSearch);
    handleFilterChange("userSearch", currentFilters.userSearch);
  };

  const renderField = (name: keyof ClientFilters, label: string) => (
    <Grid item xs>
      <BaseControl control={false}>
        <Input
          config={{
            name,
            label,
            value: currentFilters[name] ?? "",
          }}
          handleChange={handleChange}
        />
      </BaseControl>
    </Grid>
  );

  return (
    <form onSubmit={onSubmit}>
      <Grid container alignItems="center" spacing={2} role="searchbox">
        {renderField("nameSearch", clientStrings.labels.name)}
        {renderField("addressSearch", clientStrings.labels.address)}
        {renderField("emailSearch", clientStrings.labels.email)}
        {renderField("phoneSearch", clientStrings.labels.phoneNo)}

        <Grid item xs>
          <BaseControl control={false}>
            <Dropdown
              config={{
                name: "userSearch",
                label: clientStrings.labels.reapUser,
                value: currentFilters.userSearch ?? "",
                options: userOptions,
              }}
              handleChange={handleChange}
            />
          </BaseControl>
        </Grid>

        <Grid item xs={2}>
          <BaseControl control={false}>
            <Button type="submit" label={clientStrings.labels.search} />
          </BaseControl>
        </Grid>
        <Grid item xs={2}>
          <Button
            label={clientStrings.labels.reset}
            variant="outlined"
            onClick={clearFilters}
          />
        </Grid>
      </Grid>
    </form>
  );
};
