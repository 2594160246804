import axios from "../libs/axios/axios-clients";
import type {
  DeleteFunction,
  QueryFunction,
  QueryResult,
  ReadFunction,
  ReadResult,
  UpdateFunction,
  UpdateResult,
} from "../types/API";
import type { ClientData } from "../types/documents/Client";

const query: QueryFunction<ClientData> = async (body, signal?) => {
  const { data } = await axios.post<QueryResult<ClientData>>(
    "/list-clients",
    body,
    {
      signal,
    }
  );
  return data;
};

const read: ReadFunction<ClientData> = async (id, _childId, signal?) => {
  const path = `/${id}`;
  const { data } = await axios.get<ReadResult<ClientData>>(path, {
    signal,
  });
  return data;
};

const update: UpdateFunction<ClientData> = async (body) => {
  const { data } = await axios.put<UpdateResult>("/submit", body);
  return data;
};

const deleteFn: DeleteFunction = async (id) => {
  await axios.delete(`/${id}`);
};

const getClientsWithSurveys = async (signal?: AbortSignal) => {
  const { data } = await axios.get<QueryResult<ClientData>>(
    "/clients-with-surveys",
    { signal }
  );
  return data;
};

const getClientsWithNewsletters = async (signal?: AbortSignal) => {
  const { data } = await axios.get<QueryResult<ClientData>>(
    "/clients-with-newsletters",
    { signal }
  );
  return data;
};

export const api = {
  query,
  read,
  update,
  create: update,
  delete: deleteFn,
  getClientsWithSurveys,
  getClientsWithNewsletters,
};
